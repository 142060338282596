import { put, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api/ribbonHealth/ribbonHealthV2';
import * as savingApi from '../../api/savedProviders';
import * as savingFacilitiesApi from '../../api/savedFacilities';
import {
  normalizeFacility,
  normalizeProvider,
  normalizeResultsPagination,
  normalizeSearchResults,
} from '../../api/ribbonHealth/helpers';
import I18n from '../../i18n';
import {
  deleteFacilityError,
  deleteFacilityRequest,
  deleteFacilitySuccess,
  deleteProviderError,
  deleteProviderRequest,
  deleteProviderSuccess,
  fetchFacilityError,
  fetchFacilityRequest,
  fetchFacilitySuccess,
  fetchProviderError,
  fetchProviderRequest,
  fetchProviderSuccess,
  fetchSavedFacilitiesError,
  fetchSavedFacilitiesRequest,
  fetchSavedFacilitiesSuccess,
  fetchSavedProvidersError,
  fetchSavedProvidersRequest,
  fetchSavedProvidersSuccess,
  saveFacilityError,
  saveFacilityRequest,
  saveFacilitySuccess,
  saveProviderError,
  saveProviderRequest,
  saveProviderSuccess,
  searchError,
  searchRequest,
  searchSuccess,
} from './index';
import { getSavedItemIds } from './selectors';

function* search(action) {
  const { payload } = action;
  const state = yield select();
  const savedItemIds = getSavedItemIds(state);

  try {
    const { data: results, parameters } = yield api.search(payload);

    yield put(
      searchSuccess({
        results: normalizeSearchResults(
          payload.type,
          results,
          payload.insuranceIds,
          savedItemIds,
        ),
        pagination: normalizeResultsPagination(parameters),
      }),
    );
  } catch (error) {
    yield put(searchError({ error }));
  }
}

export function* deleteFacility({ payload: { id } }) {
  try {
    yield savingApi.deleteSavedFacility(id);

    yield put(deleteFacilitySuccess({ id }));
  } catch (error) {
    yield put(
      deleteFacilityError({
        errorDeletingSavedFacility:
          error.message || 'Could not delete saved facility',
      }),
    );
  }
}

export function* deleteFacilityWatcher() {
  yield takeLatest(deleteFacilityRequest, deleteFacility);
}

export function* fetchSavedFacilities() {
  try {
    const savedFacilities = yield savingFacilitiesApi.fetchSavedFacilities();

    yield put(fetchSavedFacilitiesSuccess({ savedFacilities }));
  } catch (error) {
    yield put(
      fetchSavedFacilitiesError({
        errorFetchingSavedFacilities:
          error.message || 'Could not fetch saved facilities',
      }),
    );
  }
}

export function* fetchSavedFacilitiesWatcher() {
  yield takeLatest(fetchSavedFacilitiesRequest, fetchSavedFacilities);
}

export function* saveFacility({ payload: { facility } }) {
  try {
    const savedFacility = yield savingFacilitiesApi.saveFacility(facility);

    yield put(saveFacilitySuccess({ facility: savedFacility }));
  } catch (error) {
    yield put(
      saveFacilityError({
        errorSavingFacility: error.message || 'Could not save facility',
      }),
    );
  }
}

export function* fetchFacility({ payload: { npi } }) {
  let shouldDeleteFacility = false;

  try {
    const result = yield api.fetchFacility(npi);

    /* if facility no longer exists */
    if (result.respStatusCode === 400) {
      shouldDeleteFacility = true;

      throw new Error(
        `${I18n.t(`findMedicalCare.error.errorFetchingFacility`)}`,
      );
    }

    yield put(
      fetchFacilitySuccess({
        appointmentFacility: normalizeFacility(result),
      }),
    );
  } catch (error) {
    yield put(
      fetchFacilityError({
        errorFetchingFacility: error.message || 'Could not get facility',
        shouldDeleteFacility,
      }),
    );
  }
}

export function* searchWatcher() {
  yield takeLatest(searchRequest, search);
}

export function* getFacilityWatcher() {
  yield takeLatest(fetchFacilityRequest, fetchFacility);
}

export function* saveFacilityWatcher() {
  yield takeLatest(saveFacilityRequest, saveFacility);
}

// =================
//     Providers
// =================
// TODO The following are currently not being used.
// Remove or rename these since they are duplicates from findADoctor/sagas.js
export function* deleteProvider({ payload: { id } }) {
  try {
    yield savingApi.deleteSavedProvider(id);

    yield put(deleteProviderSuccess({ id }));
  } catch (error) {
    yield put(
      deleteProviderError({
        errorDeletingSavedProvider:
          error.message || 'Could not delete saved provider',
      }),
    );
  }
}

export function* deleteProviderWatcher() {
  yield takeLatest(deleteProviderRequest, deleteProvider);
}

export function* fetchProvider({ payload: { npi } }) {
  let shouldDeleteProvider = false;

  try {
    const result = yield api.fetchProvider(npi);

    /* if provider no longer exists */
    if (result.respStatusCode === 400) {
      shouldDeleteProvider = true;

      throw new Error(`${I18n.t(`findADoctor.error.errorFetchingProvider`)}`);
    }

    yield put(
      fetchProviderSuccess({
        appointmentProvider: normalizeProvider(result),
      }),
    );
  } catch (error) {
    yield put(
      fetchProviderError({
        errorFetchingProvider: error.message || 'Could not get provider',
        shouldDeleteProvider,
      }),
    );
  }
}

export function* fetchSavedProviders() {
  try {
    const savedProviders = yield savingApi.fetchSavedProviders();

    yield put(fetchSavedProvidersSuccess({ savedProviders }));
  } catch (error) {
    yield put(
      fetchSavedProvidersError({
        errorFetchingSavedProviders:
          error.message || 'Could not fetch saved providers',
      }),
    );
  }
}

export function* fetchSavedProvidersWatcher() {
  yield takeLatest(fetchSavedProvidersRequest, fetchSavedProviders);
}

export function* getProviderWatcher() {
  yield takeLatest(fetchProviderRequest, fetchProvider);
}

export function* saveProvider({ payload: { provider } }) {
  try {
    const savedProvider = yield savingApi.saveProvider(provider);

    yield put(saveProviderSuccess({ provider: savedProvider }));
  } catch (error) {
    yield put(
      saveProviderError({
        errorSavingProvider: error.message || 'Could not save provider',
      }),
    );
  }
}

export function* saveProviderWatcher() {
  yield takeLatest(saveProviderRequest, saveProvider);
}
