/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Security } from '@okta/okta-react';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Provider } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { hotjar } from 'react-hotjar';

import {
  asyncWithLDProvider,
  withLDConsumer,
} from 'launchdarkly-react-client-sdk';

import MaintenancePage from './containers/MaintenancePage';

import { getEnvironmentName } from './helpers/environment';
import * as analytics from './helpers/analytics';
import theme from './theme';

// Import root app
import App from './containers/App/Loadable';
import SnackbarProvider from './containers/SnackbarProvider';
import ScrollToTop from './components/ScrollToTop';

// Load the favicon
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
/* eslint-enable import/no-unresolved, import/extensions */
import { isUnsupportedBrowser } from './helpers/browser';

import store from './configureStore';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import config from './okta.config';

const MOUNT_NODE = document.getElementById('app');

const initializeHotjar = () => {
  // 6 is 'Hotjar Snippet Version' or hjsv
  hotjar.initialize(process.env.HOTJAR_ID, 6);
};
initializeHotjar();

const MaintenanceSwitch = withLDConsumer()(props => {
  return props.flags.maintenancePage ? <MaintenancePage /> : <App />;
});

const SecuredRoutes = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const oktaAuth = new OktaAuth(config.oidc);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SnackbarProvider>
        <MaintenanceSwitch />
      </SnackbarProvider>
    </Security>
  );
};

const root = createRoot(MOUNT_NODE);

const render = async () => {
  const isUnsupported = isUnsupportedBrowser();
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID,
  });

  root.render(
    <Provider store={store}>
      <Router>
        <LDProvider>
          <ThemeProvider theme={theme}>
            <StylesProvider>
              <CssBaseline />
              <ScrollToTop />
              {isUnsupported ? <UnsupportedBrowser /> : <SecuredRoutes />}
            </StylesProvider>
          </ThemeProvider>
        </LDProvider>
      </Router>
    </Provider>,
  );
};

const sentryDsn = process.env.SENTRY_DSN;

const envName = getEnvironmentName(window.location.hostname);
const browserTracing = new BrowserTracing({
  routingInstrumentation: Sentry.reactRouterV6Instrumentation(
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  ),
});

Sentry.init({
  dsn: sentryDsn,
  environment: envName,
  ...(process.env.VERSION && { release: `bennie-web@${process.env.VERSION}` }),
  integrations: [browserTracing],
  tracesSampleRate: 0.1,
  beforeSendTransaction(event) {
    if (envName === 'Local' || event.transaction === '/implicit/callback') {
      return null;
    }
    return event;
  },
  beforeSend(event) {
    if (envName === 'Local') {
      return null;
    }
    return event;
  },
});

analytics.init(process.env.MIXPANEL_TOKEN, { debug: envName !== 'Production' });

render();
