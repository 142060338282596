import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import createReducer from './reducers';
import rootSaga from './store/saga';

export const history = createBrowserHistory();

const saga = createSagaMiddleware();

const rootReducer = createReducer();

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    /* TODO some redux actions will throw console errors if serializableCheck is set to true (by default it is). so that might be another tech debt issue we can look into and fix */
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      saga,
    ),
});

saga.run(rootSaga);

export default store;
